<template>
  <v-container class="p-0" fluid>
    <v-radio-group v-model="checkedBoxes" v-on:change="checkBoxChanged">
      <v-radio
        v-for="molecule in dataset.elements"
        v-bind:key="molecule.value"
        :active-class="'bg-'+molecule.message.type"
        :label="molecule.text"
        :value="molecule.value"
        off-icon="mdi-radiobox-blank"
        :on-icon="molecule.message.type === 'blue' ? 'mdi-check-circle' : 'mdi-close-circle'"
      ></v-radio>
    </v-radio-group>

    <div>
      <p
        v-for="molecule in dataset.elements"
        v-bind:key="molecule.value+'_a'"
        :class="'bg-'+molecule.message.type"
        v-if="molecule.value === checkedBoxes">{{molecule.message.text}}</p>
    </div>

  </v-container>
</template>

<script>
import { VContainer, VRadioGroup, VRadio } from 'vuetify/lib'
export default {
  components: { VContainer, VRadioGroup, VRadio },
  name: "singleChoice",
  props: ['dataset', 'answerObj', 'activeStep', 'stepId'],
  data: function () {
    return {
      checkedBoxes: '',
      firstChecked: false
     }
  },
  watch: {
    activeStep: function(newVal) {
      if (newVal === this.stepId) {
        this.checkValidated();
      }
    }
  },
  methods: {
    checkValidated () {
      if (this.checkedBoxes.length > 0) {
        this.$parent.$parent.$parent.$parent.$parent.validated = this.checkedBoxes;

        if (!this.firstChecked) {
          this.firstChecked = this.checkedBoxes;
          this.sendData();
        }
      } else {
        this.$parent.$parent.$parent.$parent.$parent.validated = false;
      }
    },
    checkBoxChanged () {
      this.$nextTick(() => {
        this.checkValidated()
      });
    },
    sendData: function() {
      window.dataLayer.push({
        'event': 'AB-Test',
        'TestName': 'finanzquiz_v1',
        'TestVersion': '_exp',
        'TestSubversion': this.firstChecked+'_first'
      });
      console.log({
        'event': 'AB-Test',
        'TestName': 'finanzquiz_v1',
        'TestVersion': '_exp',
        'TestSubversion': this.firstChecked+'_first'
      });
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.checkValidated();
    })
  }
}
</script>

<style>
.p-0 {
  padding: 0;
}
.theme--light.v-icon {
  color: #3C4543;
}

.v-input--selection-controls__input {
  margin-right: 8px;
}

.v-application .bg-blue label,
.v-application .bg-blue i {
  color: #0083AC!important;
}

.v-application .bg-red label,
.v-application .bg-red i {
  color: #BE0000!important;
}

p.bg-red {
  background: #FCE3E5;
  font-size: 14px;
  padding: 12px;
  padding-left: 46px;
  margin-top: 10px;
  margin-bottom: 24px;
  position: relative;
}
p.bg-blue {
  background: #D9EDF3;
  font-size: 14px;
  padding: 12px;
  padding-left: 46px;
  margin-top: 10px;
  margin-bottom: 24px;
  position: relative;
}

p.bg-blue:before {
  content: "\F05E0";
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0083AC;
  font-size: 22px;
  position: absolute;
  left: 12px;
}

p.bg-red:before {
  content: "\F0159";
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #BE0000;
  font-size: 22px;
  position: absolute;
  left: 12px;
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 16px!important;
}


</style>
