<template>
  <v-app>
    <div class="container white" v-if="this.state === 'created'">
      <v-window v-model="step">
        <v-window-item v-for="(slideData, index) in questionData.slides" :value="slideData.value" :key="index">
          <slide v-bind:dataset="slideData" :key="index" v-bind:stepId="index" v-bind:activeStep="step"></slide>
        </v-window-item>
      </v-window>

      <v-btn
          v-if="step !== steps"
          :disabled="!validated"
          variant="tonal"
          depressed
          @click="forward()"
      >Weiter
      </v-btn>
    </div>

    <success v-if="this.state === 'finished'"></success>

  </v-app>
</template>

<script>
import slide from './components/slide.vue';
import success from './components/success.vue';
import {VBtn, VWindow, VWindowItem, VApp } from 'vuetify/lib';
export default {
  name: 'App',
  components: {
    slide, VBtn, VWindow, VWindowItem, VApp, success
  },
  data: () => ({
    questionData: [],
    steps: false,
    step: 0,
    validated: false,
    state: 'created'
  }),
  async asyncData () {
    const questionData = await import('./assets/data.json');

    return { questionData }
  },
  async created () {
    this.questionData = await import('./assets/data.json');
    this.steps = this.questionData.slides.length;

  },
  methods: {
    forward: async function() {
      if (this.step+1 < this.steps) {
        this.sendData();

        this.step++

        this.validated = false;
      } else {
        this.sendData();
        this.finishProcess();
      }
    },
    sendToDl: function(subPage) {
      const subP = subPage || '';
        window.dataLayer.push({
          'event': 'AB-Test',
          'TestName': 'finanzquiz_v1',
          'TestVersion': '_exp',
          'TestSubversion': subP
        });
        console.log({
          'event': 'AB-Test',
          'TestName': 'finanzquiz_v1',
          'TestVersion': '_exp',
          'TestSubversion': subP
        });
    },
    sendData: function() {
      this.sendToDl(this.questionData.slides[this.step].cta.value);
      this.sendToDl(this.validated)
      //window.parent.postMessage({'event': 'fq', 'send': this.questionData.slides[this.step].cta.value}, '*');
      //window.parent.postMessage({'event': 'fq', 'send': this.validated}, '*');

    },

    finishProcess: function() {
      this.state = "finished";
      this.step++;
    }
  }, mounted() {
    window.dataLayer = window.dataLayer || [];
    const sString = window.location.search
    const urlParams = new URLSearchParams(sString);

    setTimeout(() => {
      window.dataLayer.push({
        'event': 'AB-Test',
        'TestName': 'finanzquiz_v1',
        'TestVersion': '_exp',
        'TestSubversion': 'id_'+urlParams.get('id')
      });
      console.log({
        'event': 'AB-Test',
        'TestName': 'finanzquiz_v1',
        'TestVersion': '_exp',
        'TestSubversion': 'id_'+urlParams.get('id')
      });
    },2000);
  },
}
</script>
<style>
@import './assets/font/font.css';
* {
  font-family: Open Sans,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,sans-serif;
}

.v-application--wrap > .container > .v-window {
  min-height: 450px;
}


.v-application--wrap {
  max-width: 754px!important;
  margin: 0 auto!important;
}

img {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: block;
  max-height: 150px;
}

.theme--light.v-label {
  color: #3C4543!important;
  font-size: 16px!important;
}

.v-btn {
  height: 56px!important;
  width: 100%;
  border-radius: 28px!important;
}


.v-btn__content {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
}

.theme--light.v-btn.v-btn--has-bg {
  color: white;
  background: #f34c7f!important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  -webkit-appearance: none;
  border: none;
  box-sizing: border-box;
  display: inline-flex;
  font-family: Open Sans,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0;
  line-height: inherit;
  min-width: 64px;
  outline: none;
  overflow: visible;
  position: relative;
  text-decoration: none;
  text-transform: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;

}
#__nuxt .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  color: white!important;
  background: #9DA2A1!important;
}

.theme--light.v-btn.v-btn--has-bg::-moz-focus-inner {
  border: 0;
  padding: 0
}

.theme--light.v-btn.v-btn--has-bg:active {
  outline: none
}

.theme--light.v-btn.v-btn--has-bg:hover {
  cursor: pointer
}

.theme--light.v-btn.v-btn--has-bg:disabled {
  cursor: default;
  pointer-events: none
}

html {
  box-sizing: border-box
}

*,:after,:before {
  box-sizing: inherit
}

a {
  color: #3c4543;
  text-decoration: underline
}

a:focus,a:hover {
  color: #18a7d8
}

a:active {
  color: #0083ac
}


body {
  color: #3c4543;
  font-family: Open Sans,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: 18px;
  letter-spacing: .2pt;
  line-height: 1.6
}

@media(min-width: 1024px) {
  body {
    font-size:20px
  }
}

.h1,.h2,h1,h2, .h1 b, .h2 b {
  color: #0083ac;
  font-family: Noe Text,Georgia,Times,Times New Roman,serif!important;
  -webkit-hyphens: manual;
  hyphens: manual;
  line-height: 1.1;
  word-break: break-word;
  margin-bottom: 16px;
  margin-top: 24px;
}

.h1,h1 {
  font-size: 60px;
  letter-spacing: -.05pt
}

@media(max-width: 1023px) {
  .h1,h1 {
    font-size:40px
  }
}

.h2,h2 {
  font-size: 40px;
  letter-spacing: .1pt
}

@media(max-width: 1023px) {
  .h2,h2 {
    font-size:28px
  }
}

.h3,h3 {
  font-family: Open Sans,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: 18px;
  letter-spacing: .2pt;
  line-height: 1.6
}

@media(min-width: 1024px) {
  .h3,h3 {
    font-size:20px
  }
}

.h6,h6 {
  font-weight: 700
}


body {
  color: #3c4543;
  font-family: Open Sans,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: 18px;
  letter-spacing: .2pt;
  line-height: 1.6;
}

.h1, .h2, h1, h2 {
  color: #0083ac;
  font-family: Noe Text,Georgia,Times,Times New Roman,serif;
  -webkit-hyphens: manual;
  hyphens: manual;
  line-height: 1.1;
  word-break: break-word;
  font-size: 28px;
}

.mb-24 {
  margin-bottom: 24px;
}

.theme--light.v-label {
  line-height: 26px!important;
  letter-spacing: 0.15px!important;
}

.v-radio {
  align-items: start!important;
}
</style>
