<template>
  <div class="container white">
    <div class="px-26 mb-24">
      <img src="https://www.auxmoney.com/kreditangebot/wp-content/uploads/sites/3/Screen-7.svg">
      <p class="h1"><b>Vor den Tipps, wüssten wir gerne noch ...</b></p>


      <p><b>Wie war das Quiz für Dich?</b></p>
      <v-slider
        v-model="q1"
        min="1"
        max="5"
        color="#F34C7F"
        track-color="#C4C7C6"
        step="1"
        :tick-labels="q1Labels"
        ticks="false"
        value="3"
        class="mb-42"
        v-on:change="sliderChanged1()">
      </v-slider>

      <p><b>Hat Dich das Quiz zum Nachdenken angeregt?</b></p>
      <v-slider
        v-model="q2"
        min="1"
        max="5"
        color="#F34C7F"
        track-color="#C4C7C6"
        step="1"
        :tick-labels="q2Labels"
        ticks="false"
        value="3"
        class="mb-42"
        v-on:change="sliderChanged2()">
      </v-slider>

      <v-btn  :disabled="!qFin1 || !qFin2" variant="tonal" depressed @click="finish">Weiter zu den Tipps</v-btn>
    </div>
  </div>

</template>
<script>
import {VSlider,VBtn } from 'vuetify/lib';
export default {
  name: "success",
  props: [],
  components: {VSlider,VBtn},
  data: function () {
    return {
      q1: 3,
      q2: 3,
      q1Labels: ['Schlecht' ,'' ,'' ,'' ,'Gut'],
      q2Labels: ['Gar nicht' ,'' ,'' ,'' ,'Sehr viel'],
      qFin1: false,
      qFin2: false
    }
  },
  computed: {

  },
  methods: {
    finish: function() {
      this.sendData();
      window.location.href= 'https://www.auxmoney.com/kreditangebot/finanzquiz-auswertung/?ab=true';
    },
    sendToDl:  function(subPage) {
      const subP = subPage || '';

        window.dataLayer.push({
          'event': 'AB-Test',
          'TestName': 'finanzquiz_v1',
          'TestVersion': '_exp',
          'TestSubversion': subP
        });
        console.log({
          'event': 'AB-Test',
          'TestName': 'finanzquiz_v1',
          'TestVersion': '_exp',
          'TestSubversion': subP
        });
    },
    sendData: function() {

      // Send cta log
      this.sendToDl('_feedback_gefallen' + this.q1);
      this.sendToDl('_feedback_nachdenken' + this.q2);
      this.sendToDl('_feedback_cta_weiter');
    },
    sliderChanged1 () {
      this.$nextTick(() => {
        this.qFin1 = true;
      });
    },
    sliderChanged2 () {
      this.$nextTick(() => {
        this.qFin2 = true;
      });
    }
  },
  mounted() {},
}
</script>
<style>

.mb-42 {
  margin-bottom: 42px;
}
</style>
