<template>
  <div class="px-26 mb-24">
    <img :src="dataset.image">
    <p class="h1"><b>{{dataset.headline}}</b></p>
    <p class="mb-24"><b>{{dataset.subline}}</b></p>
    <component v-bind:dataset="dataset.questions" v-bind:is="dataset.questions.type"/>
  </div>
</template>

<script>
import singleChoice from './modules/singleChoice.vue'
import slider from './modules/slider.vue'

export default {
  name: "slide",
  props: ['dataset'],
  components: {
    singleChoice,
    slider
  },
  mounted () {

  }
}
</script>

<style scoped>

</style>
