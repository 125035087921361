<template>
  <div>
    <v-slider
      v-model="selectedValue"
      min="1"
      max="5"
      color="#F34C7F"
      track-color="#C4C7C6"
      step="1"
      :tick-labels="ticksLabels"
      ticks="false"
      value="3"
      v-on:change="sliderChanged">
    </v-slider>

    <div v-if="changed">
      <p :class="'bg-'+dataset.message.type" v-html="dataset.message.text"></p>
    </div>
  </div>
</template>
<script>
import {VSlider } from 'vuetify/lib';
export default {
  name: "slider",
  components: {VSlider},
  props: ['dataset', 'answerObj', 'activeStep', 'stepId'],
  data: () => ({
      ticksLabels: [
        'Weniger als ich brauche',
        '',
        '',
        '',
        'Mehr als ich brauche'
      ],
    changed: false,
    selectedValue: 3,
  }),
  methods: {
    checkValidated () {
        this.$parent.$parent.$parent.$parent.$parent.validated = this.dataset.value + this.selectedValue;
    },
    sliderChanged () {
      this.$nextTick(() => {
        this.checkValidated();
        this.changed = true;
      });
    }
  },
  mounted() {
    //this.$nextTick(function () {
    //  this.checkValidated();
    //})
  }
}
</script>

<style>
.v-slider__tick-label {
  width: 70px;
  font-size: 12px;
  white-space: inherit;
}
.v-application--is-ltr .v-slider--horizontal .v-slider__tick:last-child .v-slider__tick-label {
  text-align: right;
}

.v-slider--horizontal .v-slider__track-container {
  height: 4px!important;
}

.v-slider__thumb {
  height: 20px;
  width: 20px;
}
.v-slider__tick--filled,
.v-slider__tick {
  background: none!important;
}

.v-slider__thumb:before {
  width: 45px!important;
  height: 45px!important;
}
</style>
