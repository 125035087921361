import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    defaultAssets: false,
    theme: {
        options: {
            customProperties: true
        },
        dark: false,
            themes: {
            light: {
                primary: "#3C4543", // stone
                    secondary: "#F34C7F", // melon
                    accent: "#E02F65", // melon dark
                    success: "#0083AC", // sky
                    info: "#0083AC", // sky
                    warning: "#EFD283", // pineapple
                    error: "#BE0000", // error
                    anchor: "#3C4543" // link, stone
            }
        }
    },
    icons: {
        iconfont: "mdi",
    }

});
